import { default as _91slug_93q3NxKvBrJOMeta } from "/app/pages/guides/[country]/[city]/[id]/[slug].vue?macro=true";
import { default as indexn4KSGiPa6TMeta } from "/app/pages/guides/[country]/[city]/[id]/index.vue?macro=true";
import { default as index7vXX5bPoSfMeta } from "/app/pages/guides/[country]/[city]/index.vue?macro=true";
import { default as indexqTjmJDwvYbMeta } from "/app/pages/guides/[country]/index.vue?macro=true";
import { default as index2KUukhUoY3Meta } from "/app/pages/guides/index.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
export default [
  {
    name: _91slug_93q3NxKvBrJOMeta?.name ?? "guides-country-city-id-slug",
    path: _91slug_93q3NxKvBrJOMeta?.path ?? "/guides/:country()/:city()/:id()/:slug()",
    meta: _91slug_93q3NxKvBrJOMeta || {},
    alias: _91slug_93q3NxKvBrJOMeta?.alias || [],
    redirect: _91slug_93q3NxKvBrJOMeta?.redirect,
    component: () => import("/app/pages/guides/[country]/[city]/[id]/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexn4KSGiPa6TMeta?.name ?? "guides-country-city-id",
    path: indexn4KSGiPa6TMeta?.path ?? "/guides/:country()/:city()/:id()",
    meta: indexn4KSGiPa6TMeta || {},
    alias: indexn4KSGiPa6TMeta?.alias || [],
    redirect: indexn4KSGiPa6TMeta?.redirect,
    component: () => import("/app/pages/guides/[country]/[city]/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: index7vXX5bPoSfMeta?.name ?? "guides-country-city",
    path: index7vXX5bPoSfMeta?.path ?? "/guides/:country()/:city()",
    meta: index7vXX5bPoSfMeta || {},
    alias: index7vXX5bPoSfMeta?.alias || [],
    redirect: index7vXX5bPoSfMeta?.redirect,
    component: () => import("/app/pages/guides/[country]/[city]/index.vue").then(m => m.default || m)
  },
  {
    name: indexqTjmJDwvYbMeta?.name ?? "guides-country",
    path: indexqTjmJDwvYbMeta?.path ?? "/guides/:country()",
    meta: indexqTjmJDwvYbMeta || {},
    alias: indexqTjmJDwvYbMeta?.alias || [],
    redirect: indexqTjmJDwvYbMeta?.redirect,
    component: () => import("/app/pages/guides/[country]/index.vue").then(m => m.default || m)
  },
  {
    name: index2KUukhUoY3Meta?.name ?? "guides",
    path: index2KUukhUoY3Meta?.path ?? "/guides",
    meta: index2KUukhUoY3Meta || {},
    alias: index2KUukhUoY3Meta?.alias || [],
    redirect: index2KUukhUoY3Meta?.redirect,
    component: () => import("/app/pages/guides/index.vue").then(m => m.default || m)
  },
  {
    name: indexN6pT4Un8hYMeta?.name ?? "index",
    path: indexN6pT4Un8hYMeta?.path ?? "/",
    meta: indexN6pT4Un8hYMeta || {},
    alias: indexN6pT4Un8hYMeta?.alias || [],
    redirect: indexN6pT4Un8hYMeta?.redirect,
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  }
]