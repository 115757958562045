import revive_payload_client_SVcYoswmXm from "/app/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@parcel+watcher@2.4.1_@types+node@20.14.1_@unocss+reset@_tskqbwpezzkfbv2jv6myny2z7a/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_cWrMbZKWqS from "/app/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@parcel+watcher@2.4.1_@types+node@20.14.1_@unocss+reset@_tskqbwpezzkfbv2jv6myny2z7a/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_rB8kAAUJjE from "/app/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@parcel+watcher@2.4.1_@types+node@20.14.1_@unocss+reset@_tskqbwpezzkfbv2jv6myny2z7a/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_IGrJ8DbzOG from "/app/node_modules/.pnpm/nuxt-site-config@2.2.12_@nuxt+devtools@1.3.3_@unocss+reset@0.60.4_floating-vue@5.2.2_@nuxt+ki_yr3n7ckzgjizudjod6wne5hgq4/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.mjs";
import payload_client_XbyVZLBQ3U from "/app/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@parcel+watcher@2.4.1_@types+node@20.14.1_@unocss+reset@_tskqbwpezzkfbv2jv6myny2z7a/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_LQSt29bpF4 from "/app/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@parcel+watcher@2.4.1_@types+node@20.14.1_@unocss+reset@_tskqbwpezzkfbv2jv6myny2z7a/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_aOvEX0BhLy from "/app/node_modules/.pnpm/@pinia+nuxt@0.5.1_rollup@4.18.0_typescript@5.4.5_vue@3.4.27_typescript@5.4.5_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_IGkcRYmoiL from "/app/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@parcel+watcher@2.4.1_@types+node@20.14.1_@unocss+reset@_tskqbwpezzkfbv2jv6myny2z7a/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import slideovers_6YYVwHBRbu from "/app/node_modules/.pnpm/@nuxt+ui@2.16.0_focus-trap@7.5.4_nuxt@3.11.2_@opentelemetry+api@1.8.0_@parcel+watcher@2.4.1_@_vnsf7grhqxhddpgbls5d6yzyyu/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.mjs";
import modals_vshciBCfrN from "/app/node_modules/.pnpm/@nuxt+ui@2.16.0_focus-trap@7.5.4_nuxt@3.11.2_@opentelemetry+api@1.8.0_@parcel+watcher@2.4.1_@_vnsf7grhqxhddpgbls5d6yzyyu/node_modules/@nuxt/ui/dist/runtime/plugins/modals.mjs";
import colors_PaqOVvGQaG from "/app/node_modules/.pnpm/@nuxt+ui@2.16.0_focus-trap@7.5.4_nuxt@3.11.2_@opentelemetry+api@1.8.0_@parcel+watcher@2.4.1_@_vnsf7grhqxhddpgbls5d6yzyyu/node_modules/@nuxt/ui/dist/runtime/plugins/colors.mjs";
import plugin_client_FebLHoYF3B from "/app/node_modules/.pnpm/@nuxtjs+color-mode@3.4.1_rollup@4.18.0/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.mjs";
import titles_fGdCXTC1q5 from "/app/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.10_@lezer+common@1.2.1_@nuxt+devtools@1.3.3_@unocss+reset@0.60.4_floatin_4zeaopklcj5pvygsfuuihv4mwi/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/titles.mjs";
import defaults_vu6cGXpg0e from "/app/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.10_@lezer+common@1.2.1_@nuxt+devtools@1.3.3_@unocss+reset@0.60.4_floatin_4zeaopklcj5pvygsfuuihv4mwi/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/defaults.mjs";
import siteConfig_AVmdmZnNOS from "/app/node_modules/.pnpm/nuxt-seo-experiments@4.0.0-rc.5_@nuxt+devtools@1.3.3_@unocss+reset@0.60.4_floating-vue@5.2.2__ljpuncirc2s72qsj5dmbfuzvli/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.mjs";
import inferSeoMetaPlugin_dAosNHvmWA from "/app/node_modules/.pnpm/nuxt-seo-experiments@4.0.0-rc.5_@nuxt+devtools@1.3.3_@unocss+reset@0.60.4_floating-vue@5.2.2__ljpuncirc2s72qsj5dmbfuzvli/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.mjs";
import chunk_reload_client_2LhmtjDsQg from "/app/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@parcel+watcher@2.4.1_@types+node@20.14.1_@unocss+reset@_tskqbwpezzkfbv2jv6myny2z7a/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import ssr_plugin_9TSLeRfWW1 from "/app/node_modules/.pnpm/@vueuse+nuxt@10.10.0_nuxt@3.11.2_@opentelemetry+api@1.8.0_@parcel+watcher@2.4.1_@types+node@2_jr4eff6vv7ex3bkqxdnflqcc4a/node_modules/@vueuse/nuxt/ssr-plugin.mjs";
import sentry_client_KEOYjM5VwZ from "/app/layers/sentry/plugins/sentry.client.ts";
import lenis_client_iqfJeXHsbL from "/app/layers/base/plugins/lenis.client.ts";
import vue_easy_lightbox_client_eXJJzv91rr from "/app/layers/base/plugins/vue-easy-lightbox.client.ts";
export default [
  revive_payload_client_SVcYoswmXm,
  unhead_cWrMbZKWqS,
  router_rB8kAAUJjE,
  _0_siteConfig_IGrJ8DbzOG,
  payload_client_XbyVZLBQ3U,
  check_outdated_build_client_LQSt29bpF4,
  plugin_vue3_aOvEX0BhLy,
  components_plugin_KR1HBZs4kY,
  prefetch_client_IGkcRYmoiL,
  slideovers_6YYVwHBRbu,
  modals_vshciBCfrN,
  colors_PaqOVvGQaG,
  plugin_client_FebLHoYF3B,
  titles_fGdCXTC1q5,
  defaults_vu6cGXpg0e,
  siteConfig_AVmdmZnNOS,
  inferSeoMetaPlugin_dAosNHvmWA,
  chunk_reload_client_2LhmtjDsQg,
  ssr_plugin_9TSLeRfWW1,
  sentry_client_KEOYjM5VwZ,
  lenis_client_iqfJeXHsbL,
  vue_easy_lightbox_client_eXJJzv91rr
]